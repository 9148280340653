import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { fakeAsync } from '@angular/core/testing';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-view-player',
  templateUrl: './view-player.component.html',
  styleUrls: ['./view-player.component.css']
})
export class ViewPlayerComponent implements OnInit {
  current_id:any;
  current_player:any;
  current_aliases:any = [];
  current_ipaliases = [];
  current_penalties = [];
  current_comments:any = [];
  current_admin_actions = [];

  aliasOrder:any = "";
  aliasReverse:any = false;

  ipAliasOrder:any = "";
  ipAliasReverse:any = false;

  penaltyOrder:any = "";
  penaltyReverse:any = false;

  adminActionOrder:any = "";
  adminActionReverse:any = false;

  commentActionOrder:any = "";
  commentActionReverse:any = false;

  modal:any = { open:false, cntx:"issue-punishment", obj:{} };

  tab:any = 0;

  constructor(private route: ActivatedRoute, private http:HttpClient, public system:SystemService, private router:Router, private _location:Location) {
    if (this.system.returnCurrentServer() == "") { this.router.navigate(['servers']); } else {
      if (this.system.returnCurrentServerObject().access_level < 32) { this.router.navigate(['servers']); }
    }
    this.current_id = this.route.snapshot.params.id;
  }

  ngOnInit(): void {
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");

    this.route.params.subscribe(routeParams => {
        // Do something on URL parameter change
        this.reloadData(routeParams.id);
    });

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.handleData(data),
        error => {
            console.error("This line is never called ",error);
        },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_aliases&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_aliases = data['aliases'],
        error => {
            console.error("This line is never called ",error);
        },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_penalties&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_penalties = data['penalties'],
        error => {
            console.error("This line is never called ",error);
        },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_ipaliases&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_ipaliases = data['ipaliases'],
        error => {
            console.error("This line is never called ",error);
        },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_admin_actions&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_admin_actions = data['admin_actions'],
        error => {
            console.error("This line is never called ",error);
        },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_comments&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_comments = data['comments'],
        error => {
            console.error("This line is never called ",error);
        },
        () => console.log("HTTP Observable completed...")
    );
  }

  reloadData(new_id) {
    this.tab = 0;
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    this.current_id = new_id;

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.handleData(data),
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_aliases&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_aliases = data['aliases'],
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_penalties&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_penalties = data['penalties'],
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_ipaliases&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_ipaliases = data['ipaliases'],
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_admin_actions&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_admin_actions = data['admin_actions'],
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_comments&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_comments = data['comments'],
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );
  }

  handleData(data) {
    console.log(data);
    this.current_player = data; 
  }

  switchTab(tab) { this.tab = tab; }
  switchModalPunishmentMode(mode){ this.modal.obj.mode = mode; }

  openModal(cntx, obj) { this.modal = { open:true, "cntx":cntx, "obj":obj }; }
  closeModal() { this.modal = { open:false, "cntx":"", obj:{} }; }

  setPromotion() {
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    this.http.get("https://" + server_ip + "/api/rcon.php?action=set_user_level&client_id=" + this.current_id + "&group_bits=" + this.modal.obj.groupbits + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.LoadInformation(),
        error => {},
        () => console.log("HTTP Observable completed...")
    );
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getBans(penalty) {
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    this.http.get("https://" + server_ip + "/api/rcon.php?action=deactivate_ban&bid=" + penalty.id)
    .subscribe(
        data => this.LoadPenalties(),
        error => {},
        () => console.log("HTTP Observable completed...")
    );

    // this.openModal('loading-ban', {});
    // this.system.clearGetBans();
    // this.system.setupGetBan(this.current_player.info.guid, penalty.id, this);
    // this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd:"get_bans" });
  }

  IssuePunishment() {
    let type = this.modal.obj.mode;
    let reason = this.modal.obj.reason;
    let duration = 0;
    let time_add = Math.floor(new Date().getTime() / 1000);

    if (this.system.currentServerObject.players.filter(player => player.client_id == this.current_id).length > 0) {
      let player_id = this.system.currentServerObject.players.filter(player => player.client_id == this.current_id)[0].id;
      if (this.modal.obj.mode == "Kick") {
        reason += " [" + this.system.returnUsername() + "]";
        this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd: "kick_player", 'id': player_id, 'reason': reason })
      
      } else if (this.modal.obj.mode == "TempBan") { 
        if (this.modal.obj.duration_type == "minute") { 
          duration = this.modal.obj.duration_time; reason += " (" + this.modal.obj.duration_time + " minutes)"; 
        
        } else if (this.modal.obj.duration_type == "hour") { 
          duration = this.modal.obj.duration_time * 60; reason += " (" + this.modal.obj.duration_time + " hours)"; 

        } else if (this.modal.obj.duration_type == "day") { 
          duration = this.modal.obj.duration_time * (24 * 60); reason += " (" + this.modal.obj.duration_time + " days)"; 
        
        } else if (this.modal.obj.duration_type == "month") { 
          duration = this.modal.obj.duration_time * ((24 * 60) * 30); reason += " (" + this.modal.obj.duration_time + " months)"; 
        
        } else if (this.modal.obj.duration_type == "year") { 
          duration = this.modal.obj.duration_time * ((24 * 60) * 365); reason += " (" + this.modal.obj.duration_time + " years)"; 
        }

        reason += " [" + this.system.returnUsername() + "]";
        this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd:"ban_player", 'id':player_id, 'reason':reason, 'duration':duration });
      
      } else if(this.modal.obj.mode == "Ban") {
        reason += " (Permanent) [" + this.system.returnUsername() + "]";
        this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd:"ban_player", 'id':player_id, 'reason':reason, 'duration':0 });
      
      }
    } else {
      if(this.modal.obj.mode == "TempBan") { 
        if (this.modal.obj.duration_type == "minute") { 
          duration = this.modal.obj.duration_time; reason += " (" + this.modal.obj.duration_time + " minutes)"; 
        
        } else if (this.modal.obj.duration_type == "hour") { 
          duration = this.modal.obj.duration_time * 60; reason += " (" + this.modal.obj.duration_time + " hours)"; 
        
        } else if (this.modal.obj.duration_type == "day") { 
          duration = this.modal.obj.duration_time * (24 * 60); reason += " (" + this.modal.obj.duration_time + " days)"; 
        
        } else if (this.modal.obj.duration_type == "month") { 
          duration = this.modal.obj.duration_time * ((24 * 60) * 30); reason += " (" + this.modal.obj.duration_time + " months)"; 
        
        } else if (this.modal.obj.duration_type == "year") { 
          duration = this.modal.obj.duration_time * ((24 * 60) * 365); reason += " (" + this.modal.obj.duration_time + " years)"; 
        }
        
        reason += " [" + this.system.returnUsername() + "]";
        this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd:"add_ban", 'guid':this.current_player.info.guid, 'reason':reason, 'duration':duration });
      
      } else if(this.modal.obj.mode == "Ban") {
        reason += " (Permanent) [" + this.system.returnUsername() + "]";
        this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd:"add_ban", 'guid':this.current_player.info.guid, 'reason':reason, 'duration':0 });
      }

      let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
      this.http.get("https://" + server_ip + "/api/rcon.php?action=issue_punishment&client_id=" + this.current_id + "&type=" + type + "&reason=" + reason + "&duration=" + duration + "&time_add=" + time_add + "&token=" + this.system.returnCurrentServerObject().token)
      .subscribe(
          data => this.LoadPenalties(),
          error => { },
          () => console.log("HTTP Observable completed...")
      );
    }
  }

  setGreeting() {
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    this.http.get("https://" + server_ip + "/api/rcon.php?action=set_greeting&client_id=" + this.current_id + "&greeting=" + this.modal.obj.greeting + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.LoadInformation(),
        error => {},
        () => console.log("HTTP Observable completed...")
    );
  }

  setMaskLevel() {
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    this.http.get("https://" + server_ip + "/api/rcon.php?action=set_mask_level&client_id=" + this.current_id + "&mask_level=" + this.modal.obj.masklevel + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.LoadInformation(),
        error => {},
        () => console.log("HTTP Observable completed...")
    );
  }

  addComment() {
    let time_add = Math.floor(new Date().getTime() / 1000);
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    this.http.get("https://" + server_ip + "/api/rcon.php?action=add_comment&client_id=" + this.current_id + "&reason=" + this.modal.obj.comment + "&time_add=" + time_add + "&admin_name=" + this.system.returnUsername() + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.LoadComments(),
        error => {},
        () => console.log("HTTP Observable completed...")
    );
  }

  removeComment(comment) {
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    this.http.get("https://" + server_ip + "/api/rcon.php?action=remove_comment&id=" + comment.id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.LoadComments(),
        error => {},
        () => console.log("HTTP Observable completed...")
    );
  }

  LoadComments() {
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_comments&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_comments = data['comments'],
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );
    this.closeModal();
  }


  LoadPenalties() {
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    
    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.handleData(data),
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );

    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player_penalties&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.current_penalties = data['penalties'],
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );

    this.closeModal();
  }

  LoadInformation(){
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    this.http .get("https://" + server_ip + "/api/rcon.php?action=get_player&id=" + this.current_id + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => this.handleData(data),
        error => { console.error("This line is never called ", error); },
        () => console.log("HTTP Observable completed...")
    );
    this.closeModal();
  }

  switchAliasOrder(order) {
    if (this.aliasOrder == order) {
      this.aliasReverse = !this.aliasReverse;

    } else {
      this.aliasOrder = order;
      this.aliasReverse = false;
    }
  }

  switchIpAliasOrder(order) {
    if (this.ipAliasOrder == order) {
      this.ipAliasReverse = !this.ipAliasReverse;

    } else {
      this.ipAliasOrder = order;
      this.ipAliasReverse = false;
    }
  }

  switchPenaltyOrder(order) {
    if (this.penaltyOrder == order) {
      this.penaltyReverse = !this.penaltyReverse;

    } else {
      this.penaltyOrder = order;
      this.penaltyReverse = false;
    }
  }

  switchAdminActionOrder(order) {
    if (this.adminActionOrder == order) {
      this.adminActionReverse = !this.adminActionReverse;

    } else {
      this.adminActionOrder = order;
      this.adminActionReverse = false;
    }
  }

  switchCommentActionOrder(order) {
    if (this.commentActionOrder == order) {
      this.commentActionReverse = !this.commentActionReverse;

    } else {
      this.commentActionOrder = order;
      this.commentActionReverse = false;
    }
  }


  goBack() { window.history.back(); }

  changedDuration() {
    if (this.system.returnCurrentServerObject().access_level < 16) {
      if (this.modal.obj.duration_type == "minute") { 
        if (this.modal.obj.duration_time > 10080) { this.modal.obj.duration_time = 10080; } 
      
      } else if (this.modal.obj.duration_type == "hour") { 
        if (this.modal.obj.duration_time > 168) { this.modal.obj.duration_time = 168; } 

      } else if (this.modal.obj.duration_type == "day") { 
        if (this.modal.obj.duration_time > 7) { this.modal.obj.duration_time = 7; } 
      }

    } else if (this.system.returnCurrentServerObject().access_level >= 16 && this.system.returnCurrentServerObject().access_level < 64) {
      if (this.modal.obj.duration_type == "minute") { 
        if (this.modal.obj.duration_time > 20160) { this.modal.obj.duration_time = 20160; } 

      } else if (this.modal.obj.duration_type == "hour") { 
        if (this.modal.obj.duration_time > 336) { this.modal.obj.duration_time = 336; } 
      
      } else if (this.modal.obj.duration_type == "day") { 
        if (this.modal.obj.duration_time > 14) { this.modal.obj.duration_time = 14; } 
      }
    }
  }

  linkToPlayer(client_id){ this.router.navigate(['/view-player/' + client_id]); }

  returnDate(dtime) {
    var date = new Date(dtime * 1000);
    var date_string = this.pad(date.getDate(), 2) + "-" + this.pad(date.getMonth() + 1, 2) + "-" + date.getFullYear() + " " + this.pad(date.getHours(), 2) + ":" + this.pad(date.getMinutes(), 2) + ":" + this.pad(date.getSeconds(), 2);
    return date_string;
  }

  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }
}
