import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServersComponent } from './servers/servers.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { OrderModule } from 'ngx-order-pipe';

import { LiveComponent } from './live/live.component';
import { SearchComponent } from './search/search.component';
import { ViewPlayerComponent } from './view-player/view-player.component';
import { AdminsComponent } from './admins/admins.component';
import { BansComponent } from './bans/bans.component';
import { BanListComponent } from './ban-list/ban-list.component';

@NgModule({
  declarations: [
    AppComponent,
    ServersComponent,
    LiveComponent,
    SearchComponent,
    ViewPlayerComponent,
    AdminsComponent,
    BansComponent,
    BanListComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    OrderModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
