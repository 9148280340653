import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from '../system.service';


@Component({
  selector: 'app-bans',
  templateUrl: './bans.component.html',
  styleUrls: ['./bans.component.css']
})
export class BansComponent implements OnInit {
  search:any = "";
  search_by:any = "all";
  searches:any = [];
  searching:any = false;
  searched:any = false;

  order:any = "time_edit";
  reverse:any = true;

  page:any = 1;
  total_pages:any = 0;
  total:any = 0;

  kicks:any = true;
  bans:any = true;
  tempbans:any = true;

  constructor(private http:HttpClient, private system:SystemService, public router:Router) { }

  ngOnInit(): void {
    if (this.system.returnCurrentServer() == "") { this.router.navigate(['servers']); } else {
      if (this.system.returnCurrentServerObject().access_level < 32) { this.router.navigate(['servers']); }
    }

    this.searching = true;
    this.searched = true;
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");

    this.getBans();
  }

  switchOrder(order) {
    this.page = 1;
    if (this.order == order) {
      this.reverse = !this.reverse;

    } else {
      this.order = order;
      this.reverse = false;
    }

    this.getBans();
  }

  searchForPlayer() {
      this.searching = true;
      this.searched = true;
      this.page = 1;
      this.total_pages = 0;
      this.total = 0;
      let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
      this.getBans();
  }

  displayLast(data) {
    this.searches = data.last_players;
    this.searching = false;
  }

  displaySearched(data) {
    this.searches = data.searched;
    this.searching = false;
    this.total_pages = data.total_page;
    this.total = data.total;
    console.log(data);
  }

  onKeypressEvent(event: any) {
    if (event.key == "Enter") { this.searchForPlayer(); }
  }

  getBans() {
    this.searching = true;
    let order_direction = "";
    if (this.reverse) { order_direction = "DESC"; } else { order_direction = "ASC"; }

    let punishment_string = "";
    if (this.kicks) { punishment_string += "&kicks=1"; } else { punishment_string += "&kicks=0"; }
    if (this.tempbans) { punishment_string += "&tempbans=1"; } else { punishment_string += "&tempbans=0"; }
    if (this.bans) { punishment_string += "&bans=1"; } else { punishment_string += "&bans=0"; }

    if (this.kicks || this.tempbans || this.bans) {
      let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
      this.http.get("https://" + server_ip + "/api/rcon.php?action=get_bans&page="+ this.page +"&token=" + this.system.returnCurrentServerObject().token + "&order_by=" + this.order + "&order_direction=" + order_direction + punishment_string)
      .subscribe(
          data => (this.displaySearched(data)),
          error => { console.error("This line is never called ", error); },
          () => console.log("HTTP Observable completed...")
      );

    } else {
      this.displaySearched({ searched:[] });
    }
  }

  linkToPlayer(player) { this.router.navigate(['/view-player/' + player.client_id]); }

  prevPage() {
    if (this.page > 1) {
      this.page -= 1;
      this.getBans();
    }
  }

  nextPage() {
    this.page += 1;
    this.getBans();
  }

  returnDate(dtime) {
    var date = new Date(dtime * 1000);
    var date_string = this.pad(date.getDate(), 2) + "-" + this.pad(date.getMonth() + 1, 2) + "-" + date.getFullYear() + " " + this.pad(date.getHours(), 2) + ":" + this.pad(date.getMinutes(), 2) + ":" + this.pad(date.getSeconds(), 2);
    return date_string;
  }

  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }
}
