import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css']
})
export class LiveComponent implements OnInit {
  @ViewChild('chatList') private chatList!: ElementRef;
  autoscroll:any = false;

  currentChat:any = "";
  currentPlayer:any = "all"; 
  currentChats:any;
  modal:any = { open:false, cntx:"kick-player", obj:{} };
  order: string = '';
  reverse:any = false;

  lastScroll:any = 0;
  autoScrollTimeout:any;

  constructor(public system:SystemService, private router:Router, private http:HttpClient) {}

  switchOrder(order) {
    if (this.order == order) {
      this.reverse = !this.reverse;

    } else {
      this.order = order;
      this.reverse = false;
    }
  }

  scrollToBottom = () => {
    try {
      if (this.system.autoscroll_chat) {
        this.chatList.nativeElement.scrollTop = this.chatList.nativeElement.scrollHeight;
      }
    } catch (err) {}
  }

  ngOnInit(): void {
    if (this.system.returnCurrentServer() == "") { this.router.navigate(['servers']); } 
    setInterval(() => { this.scrollToBottom(); }, 1000);
  }

  linkToPlayer(player){ 
    if (this.system.returnCurrentServerObject().access_level >= 32) {
      this.router.navigate(['/view-player/' + player.client_id]); 
    }
  }

  linkToSteam(player) { 
    if (this.system.returnCurrentServerObject().access_level >= 32) {
      window.open('https://steamcommunity.com/profiles/' + player.steam_id, '_blank'); 
    }
  }

  linkToIp(player) { 
    if (this.system.returnCurrentServerObject().access_level >= 32) {
      window.open('https://whatismyipaddress.com/ip/' + player.ip, '_blank');
    }  
  }

  linkToIpThreat(player) {
    if (this.system.returnCurrentServerObject().access_level >= 32) {
      window.open('https://www.ipqualityscore.com/vpn-ip-address-check/lookup/' + player.ip, '_blank'); 
    }
  }

  trackByPerson(index:number, el:any): number { return el.client_id; }

  writeToChat() {
    if (this.currentPlayer == "all") {
      this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd: "say_to_all", "admin_name": this.system.returnUsername(), "message": this.currentChat });
    } else {
      this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd: "say_to_player", "admin_name": this.system.returnUsername(), "id": this.currentPlayer, "message": this.currentChat });
    }
    this.currentChat = "";
  }

  onKeypressEvent(event: any){
    if (event.key == "Enter") { this.writeToChat(); }
  }

  issueKick() {
    this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd: "kick_player", 'id': this.modal.obj.id, 'reason': this.modal.obj.reason })
    this.closeModal();
  }

  issueBan() {
    let duration = 0;
    let player_id = this.modal.obj.id;
    let reason = this.modal.obj.reason
    
    if (this.modal.obj.mode == "TempBan") { 
      if (this.modal.obj.duration_type == "minute") { 
        duration = this.modal.obj.duration_time; 
      
      } else if (this.modal.obj.duration_type == "hour") { 
        duration = this.modal.obj.duration_time * 60; 
      
      } else if (this.modal.obj.duration_type == "day") { 
        duration = this.modal.obj.duration_time * (24 * 60);

      } else if (this.modal.obj.duration_type == "month") { 
        duration = this.modal.obj.duration_time * ((24 * 60) * 30); 
      
      } else if (this.modal.obj.duration_type == "year") { 
        duration = this.modal.obj.duration_time * ((24 * 60) * 365); 
      }
      
      this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd:"ban_player", 'id':player_id, 'reason':reason, 'duration':duration });
    
    } else if (this.modal.obj.mode == "Ban") {
      this.system.sendMessage(this.system.returnCurrentServerObject(), { cmd:"ban_player", 'id':player_id, 'reason':reason, 'duration':0 });
    }
    
    this.closeModal();
  }

  switchModalPunishmentMode(mode) { this.modal.obj.mode = mode; }

  openModal(cntx, obj) { this.modal = { open:true, "cntx":cntx, "obj":obj }; }

  closeModal() { this.modal = { open:false, "cntx":"", obj:{} }; }

  pad ( num, size ) {
    if (num.toString().length >= size) return num;
    return ( Math.pow( 10, size ) + Math.floor(num) ).toString().substring( 1 );
  }

  testChats() { console.log(this.currentChats); }

  addComment() {
    let time_add = Math.floor(new Date().getTime() / 1000);
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
    
    this.http.get("https://" + server_ip + "/api/rcon.php?action=add_comment&client_id=" + this.modal.obj.id + "&reason=" + this.modal.obj.comment + "&time_add=" + time_add + "&admin_name=" + this.system.returnUsername() + "&token=" + this.system.returnCurrentServerObject().token)
    .subscribe(
        data => { this.closeModal() },
        error => {},
        () => console.log("HTTP Observable completed...")
    );
  }

  changedDuration() {
    if (this.system.returnCurrentServerObject().access_level < 16){
      if (this.modal.obj.duration_type == "minute") { 
        if (this.modal.obj.duration_time > 10080) { this.modal.obj.duration_time = 10080; } 
      } else if (this.modal.obj.duration_type == "hour") { 
        if (this.modal.obj.duration_time > 168) { this.modal.obj.duration_time = 168; } 
      } else if (this.modal.obj.duration_type == "day") { 
        if (this.modal.obj.duration_time > 7) { this.modal.obj.duration_time = 7; } 
      }

    } else if (this.system.returnCurrentServerObject().access_level >= 16 && this.system.returnCurrentServerObject().access_level < 64) {
      if (this.modal.obj.duration_type == "minute") { 
        if (this.modal.obj.duration_time > 20160) { this.modal.obj.duration_time = 20160; } 
      } else if (this.modal.obj.duration_type == "hour") { 
        if (this.modal.obj.duration_time > 336) { this.modal.obj.duration_time = 336; } 
      } else if (this.modal.obj.duration_type == "day") { 
        if (this.modal.obj.duration_time > 14) { this.modal.obj.duration_time = 14; } 
      }
    }
  }

  autoScrollChanged() {
    this.system.switchAutoscrollChat();
    if (!this.system.autoscroll_chat) { clearTimeout(this.autoScrollTimeout); }
  }

  onScroll(ev) {
    if (this.system.autoscroll_chat) {
      clearTimeout(this.autoScrollTimeout);
      this.autoScrollTimeout =  setTimeout(() => { this.system.autoscroll_chat = true; }, 10000);
    }

    if (this.lastScroll != 0) {
      if (this.system.autoscroll_chat) {
        if (ev.target.scrollTop < this.lastScroll) { this.system.autoscroll_chat = false; }
      }
    }
    
    this.lastScroll = ev.target.scrollTop;
  }

  returnTimestamp(timestam) {
    let timestamp = new Date(timestam * 1000);
    return timestamp;
  }

}
