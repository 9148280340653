import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-ban-list',
  templateUrl: './ban-list.component.html',
  styleUrls: ['./ban-list.component.css']
})
export class BanListComponent implements OnInit {
  search:any = "";
  search_by:any = "all";
  searches:any = [];
  searching:any = false;
  searched:any = false;

  order:any = "time_edit";
  reverse:any = true;

  page:any = 1;
  total_pages:any = 0;
  total:any = 0;

  kicks:any = true;
  bans:any = true;
  tempbans:any = true;

  constructor(private http:HttpClient, private system:SystemService, public router:Router) { }

  ngOnInit(): void {
    if (this.system.returnCurrentServer() == "") { this.router.navigate(['servers']); } else {
      if (this.system.returnCurrentServerObject().access_level < 32) { this.router.navigate(['servers']); }
    }

    this.searching = true;
    this.searched = true;
    let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");

    this.getBansList();
  }

  switchOrder(order) {
    this.page = 1;
    
    if (this.order == order) {
      this.reverse = !this.reverse;

    } else {
      this.order = order;
      this.reverse = false;
    }

    this.getBansList();
  }

  searchForPlayer() {
      this.searching = true;
      this.searched = true;
      this.page = 1;
      this.total_pages = 0;
      this.total = 0;
      let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");

      this.getBansList();
  }

  displayLast(data) {
    this.searches = data.last_players;
    this.searching = false;
  }

  displaySearched(data) {
    this.searches = data.searched;
    this.searching = false;
    this.total_pages = data.total_page;
    this.total = data.total;
    console.log(data);
  }

  onKeypressEvent(event: any) {
    if (event.key == "Enter") { this.searchForPlayer(); }
  }

  getBansList() { this.system.getBanList(this); }

  loadBans(bans) {
    this.searched = bans;
    console.log(this.searched);
  }

  linkToPlayer(player){ this.router.navigate(['/view-player/' + player.client_id]); }

  prevPage() {
    if (this.page > 1) { this.page -= 1; }
  }

  nextPage() {
    this.page += 1;
    this.getBansList();
  }

  returnDate(dtime) {
    var date = new Date(dtime * 1000);
    var date_string = this.pad(date.getDate(), 2) + "-" + this.pad(date.getMonth() + 1, 2) + "-" + date.getFullYear() + " " + this.pad(date.getHours(), 2) + ":" + this.pad(date.getMinutes(), 2) + ":" + this.pad(date.getSeconds(), 2);
    return date_string;
  }

  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }
}
