import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { fakeAsync } from '@angular/core/testing';
import { Router } from '@angular/router';
import { SystemService } from './system.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  username:any = "";
  password:any = "";
  error_login:any = false;

  modal:any = { open:false, 'cntx':'', obj:{} }

  constructor(public system:SystemService, private router:Router, private http:HttpClient) {}

  Login() { this.system.Login(this.username, this.password, this); }

  Logout() { this.system.Logout(); }

  onKeypressEvent(event: any){
    if (event.key == "Enter") { this.Login(); }
  }

  openModal(cntx, obj) {
    this.modal = { open:true, 'cntx':cntx, 'obj':obj };
    if (cntx == 'settings') { this.loadUsers(); }
  }
  
  closeModal() { this.modal.open = false; }

  loadUsers() {
    this.http.get("centcom_core.php?action=get_users") 
    .subscribe(data => { this.modal.obj.users = data['users']; }, error => {});
  }

  title = 'rcon-app';
}
