import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminsComponent } from './admins/admins.component';
import { BanListComponent } from './ban-list/ban-list.component';
import { BansComponent } from './bans/bans.component';
import { LiveComponent } from './live/live.component';
import { SearchComponent } from './search/search.component';
import { ServersComponent } from './servers/servers.component';
import { ViewPlayerComponent } from './view-player/view-player.component';

const routes: Routes = [
  { path: 'servers', component: ServersComponent },
  { path:'live', component:LiveComponent },
  { path:'search', component:SearchComponent },
  { path:'admins', component:AdminsComponent },
  { path:'punishments', component:BansComponent },
  { path:'ban-list', component:BanListComponent },
  { path: 'view-player/:id', component: ViewPlayerComponent },
  { path: '',   redirectTo: '/servers', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
