import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-servers',
  templateUrl: './servers.component.html',
  styleUrls: ['./servers.component.css']
})
export class ServersComponent implements OnInit {

  constructor(public system:SystemService, private router:Router, private http:HttpClient) { }

  ngOnInit(): void {
    this.system.currentServerObject = {};
    this.system.currentServer = "";
  }

  selectServer(server_path) {
    this.system.currentServerObject = this.system.returnServers().filter(server => server.server_path == server_path)[0];
    if(this.system.currentServerObject.connected) {
      this.system.currentServer = server_path;
      this.system.sendMessage(this.system.currentServerObject, { cmd:"get_players" });
      this.router.navigate(['live']);
      for(let i = 0; i < this.system.currentServerObject.messages.length; i++) {
        if(this.system.returnCurrentServerObject().access_level < 32) {
          if(this.system.returnCurrentServerObject().messages[i].message.indexOf("connected") > -1){
            this.system.returnCurrentServerObject().messages[i].message.replace(/ *\([^)]*\) */g, "(***.***.***.***)");
          }
        }
      }
      let server_ip = this.system.returnCurrentServerObject().ip.replace("wss://", "");
      this.http .get("https://" + server_ip + "/api/rcon.php?action=get_level&token=" + this.system.returnCurrentServerObject().token)
      .subscribe(
          data => (this.system.setServerAccessLevel(data)),
          error => { },
          () => console.log("HTTP Observable completed...")
      );
    }
  }

}
