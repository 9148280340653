import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

var some;
@Injectable({ providedIn: 'root' })
export class SystemService {
  public autoscroll_chat:any = false;
  public chat_types = ["command", "system", "global", "side", "vehicle",  "custom", "group", "direct"];

  logged:any = false;
  is_admin:any = "0";
  logging:any = false;
  username:any = "";
  admin:any = false;

  banList:any = [];
  banTimeout:any;
  banGUID:any = "";
  banID:any = "";
  banWindow:any;

  banRequester:any = null;

  servers:any = [];

  currentServer:any = "";
  currentServerObject:any;
  serversInterval:any;

  constructor(private http:HttpClient, private router:Router) {
    some = this;
    // this.http.get("centcom_core.php?action=get_logged") .subscribe(
    //     data => { 
    //       this.logged = data['logged']; 
    //       this.is_admin = data['is_admin'];
    //       if (this.logged) { this.username = data['username']; this.LoadServers(); } 
    //     },
    //     error => { }
    // );
  }

  Login(username, password, loginForm) { 
    this.logging = true;

    this.http.post("https://panel.77th-jsoc.com:8080/login", { "username": username, "password": password })
    .subscribe(
        data => { 
          this.logged = data['logged'];
          if (this.logged) {
            this.username = data['username'];
            this.is_admin = data['is_admin'];

            // this.LoadServers();
            loginForm.error_login = false;
            setTimeout(() => { this.logging = false; }, 1500);

          } else {
            if (data['login_error']) { 
              loginForm.error_login = true;
              setTimeout(() => { this.logging = false; }, 1500); 
            }
          }
        },
        error => { setTimeout(() => { this.logging = false; }, 1500); }
    );
  }

  Logout() {
    this.http.get("https://panel.77th-jsoc.com:8080/logout")
    .subscribe(
      data => { 
        this.logged = data['logged'];
        clearInterval(this.serversInterval);
        for (let i = 0; i < this.servers.length;i++) { this.servers[i].socket.close(); }
        this.servers = [];
        this.router.navigate(['servers']);
      },
      error => { }
    );
  }

  LoadServers() {
    this.http.get("centcom_core.php?action=get_servers") .subscribe(
      data => { 
        this.servers = data['servers'];
        this.autoscroll_chat = data['autoscroll_chat'];

        this.InitializeServers();
        clearInterval(this.serversInterval);
        this.serversInterval = setInterval(() => { this.keepAliveServers(); }, 10000);
      },
      error => { }
    );
  }

  InitializeServers() {
    for(var i = 0; i < this.servers.length; i++){
      this.servers[i].server_path = this.servers[i].ip + ":" + this.servers[i].port + "/";

      // // Connection opened
      // let socket = new WebSocket(this.servers[i].ip + ":" + this.servers[i].port);

      // socket.onopen = (event) => {
      //   let currentServer = some.servers.filter(server => server.server_path == socket.url)[0];
      //   socket.send(JSON.stringify({ cmd:"login", "user":currentServer.user, "pass":currentServer.pass }));
      // };

      // socket.onmessage = (event) => {
      //   let msg = JSON.parse(event.data);
      //   let currentServer = some.servers.filter(server => server.server_path == socket.url)[0];

      //   if (msg.cmd == "login") {
      //     currentServer.token = msg.token;
      //     currentServer.connected = true;

      //     socket.send(JSON.stringify({ cmd:"get_server", "token":currentServer.token }));
      //     socket.send(JSON.stringify({ cmd:"get_players", "token":currentServer.token }));
      //     socket.send(JSON.stringify({ cmd:"get_chat", "token":currentServer.token }));

      //   } else if (msg.cmd == "get_server") {
      //     currentServer.data = msg.server;

      //   } else if (msg.cmd == "get_chat") {
      //     for (let i = 0; i < msg.messages.length; i++) { 
      //       if (msg.messages[i].channel == "unknown") { msg.messages[i].channel = "custom" } 
      //       if (this.returnCurrentServerObject().access_level < 32) {
      //         if (msg.messages[i].message.indexOf("connected") > -1) {
      //           msg.messages[i].message.replace(/ *\([^)]*\) */g, "(***.***.***.***)");
      //         }
      //       }
      //     }
      //     currentServer.messages = msg.messages;

      //   } else if(msg.cmd == "get_players") {
      //     currentServer.players = msg.players;

      //   } else if(msg.cmd == "chat_message") {
      //     if (currentServer.messages != undefined) {
      //       if (msg.chat.channel == "unknown") { msg.chat.channel = "custom"; }
      //       if (this.returnCurrentServerObject().access_level < 32) {
      //         if (msg.chat.message.indexOf("connected") > -1) {
      //           msg.chat.message.replace(/ *\([^)]*\) */g, "(***.***.***.***)");
      //         }
      //       }
      //       currentServer.messages.push(msg.chat);
      //     }

      //   } else if(msg.cmd == "get_bans") {
      //     console.log(msg.bans);
      //     if (this.banRequester == null) {
      //       for (let i = 0; i < msg.bans.length; i++) { this.banList.push(msg.bans[i]); }

      //       clearTimeout(this.banTimeout);
      //       this.banTimeout = setTimeout(() => {
      //         for (let i = 0; i < this.banList.length; i++) {
      //           if (this.banList[i][1] == this.banGUID) {
      //             this.sendMessage(currentServer, { cmd: "remove_ban", bid: this.banList[i][0] });
      //           }
      //         }

      //         let server_ip = currentServer.ip.replace("wss://", "");
      //         this.http.get("https://" + server_ip + "/api/rcon.php?action=deactivate_ban&bid=" + this.banID)
      //         .subscribe(
      //             data => this.clearBanVariables(),
      //             error => {},
      //             () => console.log("HTTP Observable completed...")
      //         );
      //       }, 2000);

      //     } else {
      //       this.banRequester.loadBans(msg.bans);
      //       this.banRequester = null;
      //     }
      //   }
      // };

      // socket.onclose = (event) => {
      //   let currentServer = some.servers.filter(server => server.server_path == socket.url)[0];
      //   currentServer.connected = false;
      // };

      // socket.onerror = (event) => {
      //   let currentServer = some.servers.filter(server => server.server_path == socket.url)[0];
      //   currentServer.connected = false;
      // };

      // this.servers[i].socket = socket;
    }
  }

  // ReconnectSocket(server) {
  //   let socket = new WebSocket(server.ip + ":" + server.port);
  //     socket.onopen = (event) => {
  //       socket.send(JSON.stringify({ cmd: "login", "user": server.user, "pass": server.pass }));
  //     };

  //     socket.onmessage = (event) => {
  //       let msg = JSON.parse(event.data);
  //       if (msg.cmd == "login"){
  //         server.token = msg.token;
  //         server.connected = true;

  //         socket.send(JSON.stringify({ cmd: "get_server", "token": server.token }));
  //         socket.send(JSON.stringify({ cmd: "get_players", "token": server.token }));
  //         socket.send(JSON.stringify({ cmd: "get_chat", "token": server.token }));

  //       } else if (msg.cmd == "get_server") {
  //         server.data = msg.server;

  //       } else if (msg.cmd == "get_chat") {
  //         for (let i = 0; i < msg.messages.length; i++) { 
  //           if (msg.messages[i].channel == "unknown") { msg.messages[i].channel = "custom" } 
  //           if (this.returnCurrentServerObject().access_level < 32) {
  //             if (msg.messages[i].message.indexOf("connected") > -1) {
  //               msg.messages[i].message.replace(/ *\([^)]*\) */g, "(***.***.***.***)");
  //             }
  //           }
  //         }
  //         server.messages = msg.messages;

  //       } else if(msg.cmd == "get_players") {
  //         server.players = msg.players;

  //       } else if(msg.cmd == "chat_message") {
  //         if (server.messages != undefined) {
  //           if (msg.chat.channel == "unknown") { msg.chat.channel = "custom"; }
  //           if (this.returnCurrentServerObject().access_level < 32) {
  //             if (msg.chat.message.indexOf("connected") > -1) {
  //               msg.chat.message.replace(/ *\([^)]*\) */g, "(***.***.***.***)");
  //             }
  //           }
  //            server.messages.push(msg.chat);
  //         }

  //       } else if(msg.cmd == "get_bans") {
  //         console.log(msg.bans);
  //         if (this.banRequester == null) {
  //           for (let i = 0; i < msg.bans.length; i++) { this.banList.push(msg.bans[i]); }

  //           clearTimeout(this.banTimeout);
  //           this.banTimeout = setTimeout(()=>{
  //             for (let i = 0; i < this.banList.length; i++) {
  //               if (this.banList[i][1] == this.banGUID) {
  //                 this.sendMessage(server, { cmd: "remove_ban", bid: this.banList[i][0] });
  //               }
  //             }

  //             let server_ip = server.ip.replace("wss://", "");
  //             this.http.get("https://" + server_ip + "/api/rcon.php?action=deactivate_ban&bid=" + this.banID)
  //             .subscribe(
  //                 data => this.clearBanVariables(),
  //                 error => { },
  //                 () => console.log("HTTP Observable completed...")
  //             );
  //           }, 2000);

  //         } else {
  //           this.banRequester.loadBans(msg.bans);
  //           this.banRequester = null;
  //         }
  //       }
  //     };

  //     socket.onclose = (event) => { server.connected = false; };
  //     socket.onerror = (event) => { server.connected = false; };
  //     server.socket = socket;
  // }

  clearBanVariables() {
    this.banWindow.LoadPenalties();
    this.banWindow.closeModal();
    this.banWindow = null;
    this.banGUID = "";
    this.banID = "";
  }

  getBanList(requester) {
    this.banRequester = requester;
    this.sendMessage(this.currentServerObject, { cmd: "get_bans" });
  }

  keepAliveServers() {
    for (var i = 0; i < this.servers.length; i++){
      // if (this.servers[i].connected) {
      //   this.servers[i].socket.send(JSON.stringify({ cmd: "get_server", "token": this.servers[i].token }));
      // } else {
      //   this.ReconnectSocket(this.servers[i]);
      // }
    }
  }

  setupGetBan(guid, id, modal) { this.banGUID = guid; this.banID = id; this.banWindow = modal; }

  clearGetBans() { this.banList = []; }

  sendMessage(server, msg) { 
    msg.token = server.token;
    // server.socket.send(JSON.stringify(msg)); 
  }

  switchAutoscrollChat() {
    let scroll_value = 1;
    if (this.autoscroll_chat) { scroll_value = 1; } else { scroll_value = 0; }

    // this.http.get("centcom_core.php?action=set_autoscroll_chat&set_autoscroll=" + scroll_value) 
    // .subscribe(data => {}, error => {});
  }

  SwitchChannelActive(channel){
    if (this.chat_types.filter(chat => chat == channel).length > 0) {
      for (let i = 0; i < this.chat_types.length; i++) { if(this.chat_types[i] == channel) { this.chat_types.splice(i, 1); } }
    } else {
      this.chat_types.push(channel);
    }
  }

  setServerAccessLevel(data){
    this.currentServerObject.access_level = data['access_level'];
    console.log(this.currentServerObject);
  }

  returnCurrentMessages() { return this.currentServerObject.messages.filter(message => this.returnMessageIsAllowed(message.channel)); }
  returnMessageIsAllowed(channel) { if(this.chat_types.filter(chat_type => chat_type == channel).length > 0) { return true; } else { return false } }
  returnUsername() { return this.username; }
  returnLogged() { return this.logged; }
  returnIsAdmin() { return this.admin; }
  returnAdmin() { return this.is_admin; }
  returnLogging() { return this.logging };
  returnServers() { return this.servers; }
  returnCurrentServer() { return this.currentServer; }
  returnCurrentServerObject() { return this.currentServerObject; }
}
